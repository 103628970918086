import groq from 'groq';

export const MEDIA_FRAGMENT = `#graphql
  fragment Media on Media {
    __typename
    mediaContentType
    alt
    previewImage {
      url
    }
    ... on MediaImage {
      id
      image {
        url
        width
        height
      }
    }
    ... on Video {
      id
      sources {
        mimeType
        url
      }
    }
    ... on Model3d {
      id
      sources {
        mimeType
        url
      }
    }
    ... on ExternalVideo {
      id
      embedUrl
      host
    }
  }
`;

export const PRODUCT_CARD_FRAGMENT = `#graphql
  fragment ProductCard on Product {
    id
    title
    publishedAt
    handle
    variants(first: 1) {
      nodes {
        id
        image {
          url
          altText
          width
          height
        }
        price {
          amount
          currencyCode
        }
        compareAtPrice {
          amount
          currencyCode
        }
        selectedOptions {
          name
          value
        }
        product {
          handle
          title
        }
      }
    }
  }
`;

export const PRODUCT_FRAGMENT = `#graphql
  fragment ProductMain on Product {
    id
    title
    publishedAt
    handle
    options {
      name
      values
    }
    tags
    variants(first: 30) {
      nodes {
        title
        id
        availableForSale
        image {
          url
          altText
          width
          height
        }
        price {
          amount
          currencyCode
        }
        compareAtPrice {
          amount
          currencyCode
        }
        selectedOptions {
          name
          value
        }
        product {
          handle
          title
        }
      }
    }
  }
`;

//Sanity

export const LINK_INTERNAL = groq`
  _key,
  _type,
  title,
  ...reference-> {
    "documentType": _type,
    (_type == "collection") => {
      "slug": "/collections/" + store.slug.current,
    },
    (_type == "home") => {
      "slug": "/",
    },
    (_type == "page") => {
      "slug": "/pages/" + slug.current,
    },
    (_type == "product") => {
      "slug": "/products/" + store.slug.current,
    },
    (_type == "category") => {
      "slug": "/blogs/" + slug.current,
    },
  }
`;

export const MODULE_HERO_BANNER = groq`
	banner_image, 
  banner_image_en,
	banner_title, 
	"link": links[0]{
		...,
		(_type == "linkInternal") => {
			${LINK_INTERNAL}
		}
	},
  "link_en": links_en[0]{
		...,
		(_type == "linkInternal") => {
			${LINK_INTERNAL}
		}
	}
`;

export const MODULE_SELECTED_SHOPIFY_COLLECTIONS = groq`
  shopify_collections[] {
    collection_thumbnail,
    shopify_collection->,
  }
`;

export const MODULE_SELECTED_SHOPIFY_PRODUCTS = (languageCode: string) => groq`
  "section_title": section_title["${languageCode}"],
  modules[] {
    "product": product-> {
        ...,
        store {
            ...,
            variants[]->
        }
    }
  }
`;

export const MODULE_BLOGS_SECTION = (languageCode: string) => groq`
    "section_title": section_title["${languageCode}"],
    "most_popular_posts_title": most_popular_posts_title["${languageCode}"],
    "categories_title": categories_title["${languageCode}"],
    "blogs": selected_blogs[]->{
        "blog_title": blog_title["${languageCode}"],
        blog_categories,
        "blog_description": blog_description["${languageCode}"],
        blog_published_date,
        blog_tags,
        blog_thumbnail,
        slug,
        type,
        _rev,
        "blog_category":blog_categories[0]->{
            ...,
            "category_title": category_title["${languageCode}"]
        }
    },
    "recent_blogs": *[_type == 'blog']| order(blog_published_date desc){
      "blog_title": blog_title["${languageCode}"],
      blog_categories,
      "blog_category":blog_categories[0]->{
        ...,
        "category_title": category_title["${languageCode}"]
      },
      "blog_description": blog_description["${languageCode}"],
      "blog_title_en": blog_title["en"],
      "blog_title_zh_tw": blog_title["zh_TW"],
      "blog_description_en": blog_description["en"],
      "blog_description_zh_tw": blog_description["zh_TW"],
      blog_published_date,
      blog_tags,
      blog_thumbnail,
      slug,
      type,
      _id,
      _rev
    }[0..4],
    "most_popular_posts": most_popular_posts[]->{
        "blog_title": blog_title["${languageCode}"],
        "blog_categories": blog_categories[]->{
            _rev,
            _type,
            "category_title": category_title["${languageCode}"],
            slug
        },
        "blog_category":blog_categories[0]->{
            ...,
            "category_title": category_title["${languageCode}"]
        },
        "blog_description": blog_description["${languageCode}"],
        blog_published_date,
        blog_tags,
        blog_thumbnail,
        slug,
        type,
        _rev
    },
    "blog_categories": blog_categories[]->{
        _rev,
        _type,
        "category_title": category_title["${languageCode}"],
        slug
    }
`;

export const MODULE_GRID_SECTION = (languageCode: string) => groq`
"section_title": section_title["${languageCode}"],
    blocks[] {
        ...,
        "title": title["${languageCode}"],
        "description": description["${languageCode}"]
    }
`;

export const MODULE_EMAIL_SIGNUP = (languageCode: string) => groq`
    "section_title": section_title["${languageCode}"],
    image,
    "label": label["${languageCode}"],
    text_colour,
    bg_colour,
    "button_text": button_text["${languageCode}"],
    button_text_colour,
    button_bg_colour
`;

export const MODULE_SOCIAL_REVIEWS = (languageCode: string) => groq`
  ...,
  "section_title": section_title["${languageCode}"]
`;

export const MODULE_LOOX_REVIEWS = groq`
  ...
`;

export const MODULE_PRODUCT_BUNDLE = groq`
  ...
`;

export const MODULE_SANITY_IMAGE = groq`
  ...,
  "link": link[0]{
    ...,
    (_type == "linkInternal") => {
      ${LINK_INTERNAL}
    }
  }
`;

export const MODULE_SANITY_BUTTON = groq`
  ...,
  "link":links[0]{
    ...,
    (_type == "linkInternal") => {
      ${LINK_INTERNAL}
    }
  },
`;

export const MODULE_CUSTOMER_REVIEWS = (languageCode: string) => groq`
  ...,
  "section_title":section_title["${languageCode}"]
`;

export const COLUMN_CONTENT = (languageCode: string) => groq`
  ...,
  (_type == "module.customerReviews") => {
    ${MODULE_CUSTOMER_REVIEWS(languageCode)}
  },
  (_type == "image") => {
    ${MODULE_SANITY_IMAGE}
  },
  (_type == "button") => {
    ${MODULE_SANITY_BUTTON}
  }
`;

export const MODULE_TWO_COLUMNS = (languageCode: string) => groq`
  "left_column": left_column[]{
    ${COLUMN_CONTENT(languageCode)}
  },
  "right_column": right_column[]{
    ${COLUMN_CONTENT(languageCode)}
  }
`;

export const ALL_SANITY_MODULES = (languageCode: string) => groq`
  ...,
  _key,
  _type,
  (_type == "module.hero_banner") => {
    ${MODULE_HERO_BANNER}
  },  
  (_type == "custom.selectShopifyCollections") => {
    ${MODULE_SELECTED_SHOPIFY_COLLECTIONS}
  },  
  (_type == "module.selectedShopifyProducts") => {
    ${MODULE_SELECTED_SHOPIFY_PRODUCTS(languageCode)}
  },  
  (_type == "module.blogsSection") => {
    ${MODULE_BLOGS_SECTION(languageCode)}
  }, 
  (_type == "module.gridSection") => {
    ${MODULE_GRID_SECTION(languageCode)}
  },  
  (_type == "module.emailSignUp") => {
    ${MODULE_EMAIL_SIGNUP(languageCode)}
  },  
  (_type == "module.socialReviews") => {
    ${MODULE_SOCIAL_REVIEWS(languageCode)}
  }, 
  (_type == "module.looxReviews") => {
    ${MODULE_LOOX_REVIEWS}
  },  
  (_type == "module.productBundle") => {
    ${MODULE_PRODUCT_BUNDLE}
  },
  (_type == "image") => {
    ${MODULE_SANITY_IMAGE}
  },
  (_type == "module.twoColsGrid") => {
    ${MODULE_TWO_COLUMNS(languageCode)}
  },
  (_type == "custom.carousel") => {
    ...
  },
  (_type == "block") => {
    ...,
    "markDefs": markDefs[] {
      ...,
      (_type == "annotationLinkInternal") => {
        ${LINK_INTERNAL}
      }
    }
  },
  (_type == "button") => {
    ...,
    "link": links[0]{
      ...,
      "title": title["${languageCode}"],
      ...,
      (_type == "linkInternal") => {
        ${LINK_INTERNAL}
      }
    }
  }
`